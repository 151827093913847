#book-telec-form .stripe-input {
  padding: 7.5px 11px;
}

#checkout-card  .ant-card-body{

}

#unpadded-card {
  padding: 0;
}

.booking-form {
  margin: auto;
  width: 800px;
  height: 100%;
}

.booking-steps-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.booking-checkout-form {
  margin-top: 15px;
}

.accepted-cards-list {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.ant-checkbox-form .ant-form-item{
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .booking-form {
    margin: 0;
    width: min-content;
    height: 100%;
  }

  .booking-steps-container{
    width: 80vw;
  }

  .booking-form .ant-card-actions > li > span {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  .booking-form .ant-card-actions > li > span > button {
    margin-bottom: 10px;
  }
  
  .booking-form .cPurposes-select-form {
    width: 80vw;
  }

  .booking-checkout-form {
   width: 80vw;
  }

  .accepted-cards-list {
    flex-wrap: wrap;
  }

  .booking-form  .ant-card-body { 
    width: min-content;
  }
}