.result-card {
  margin: 20px 0;
}

.result-card .ant-card-body {
  display: flex;
  padding: 14px;
  flex-direction: column;
}

.card-div {
  width: 100%;
}

/* .doctor-card {
  padding: 30px;
} */

.doctor-card-identity {
  display: flex;
  align-items: center;
}

.doctor-card-identity-text {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
}

.doctor-card-info {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.doctor-list-container {
  max-width: 1100px;
  margin: auto;
  opacity: 0.85;
}

.ant-descriptions-item-content {
  word-break: inherit;
}

.CheckboxDoctor {
  margin-top:20px;
}
.result-card .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 6px 9px;
}
.ant-descriptions-item-content{
  display: flex;
    flex-direction: row-reverse;
}

.flexCenter {
    align-items : center;
    justify-content: center;
    display: flex;
}

th{
  width:40% !important;
}

@media (max-width: 600px) {
  .card-div {
    width: min-content;
  }
  .result-card .ant-card-body {
    flex-wrap: wrap;
    padding: 0;
  }

  .doctor-list-container {
    margin: auto;
  }

  .result-card .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 6px 9px;
  }

  /* .doctor-card {
    padding: 30px 5px;
    margin: auto;
  } */


  .doctor-info-item {
    width: 40vw;
    word-break: break-word;
  }

  .show-lg {
    display: none;
  }

  .responsive-calendar {
    width: auto !important;
    margin: auto;
  }
}
