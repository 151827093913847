.profile-section .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
}

.profile-section .ant-tabs-nav-list {
    width: 100%
}

.stetoscope-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
}

.ant-tabs-tab-active .stetoscope-icon {
    color: #f1a043;
}

.profile-section .ant-tabs-nav-operations {
    display: none !important;
}

.profile-tab {
    font-size: 20px;
}