.profile-gender-icon {
  font-size: 40px;
  margin-top: 10px;
}

.profile-gender-card {
  margin: 0px 15px;
}

#initial-config {
  opacity: 0.85;
}

/* .profile-selected-card {
  box-shadow: 0 1px 1px -2px rgb(24, 144, 255), 0 0px 1px 0 rgb(24, 144, 255),
    0 0px 12px 2px rgb(24, 144, 255);
} */

.profile-selected-gender {
  color: var(--antd-wave-shadow-color);
}

.profile-selected-gender .ant-card-meta-title {
  color: var(--antd-wave-shadow-color);
}

.card-container {
  margin: auto;
  width: 800px;
}

.custom-birthdate-inputs {
  display: flex;
  justify-content: stretch;
}


#initial-config .form-child-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.profile-gender-container {
  display: flex;
}

@media (max-width: 600px) {
  .card-container {
    width: auto !important;
  }

  #initial-config .ant-spin-nested-loading {
    width: min-content;
  }

  #initial-config .ant-progress-line {
    width: 85%;
  }

  #birthdate-form .ant-form-item {
    width: max-content;
  }

  #birthdate-form .form-submit-button {
    width: 100%;
  }

  .custom-birthdate-inputs {
    flex-wrap: wrap;
  }

  #initial-config .form-child-container {
    align-items: flex-start;
  }

  .profile-gender-container {
    flex-wrap: wrap;
    min-width: 85vw;
    justify-content: center;
  }

  .profile-gender-card {
    margin: 5px;
  }

  #initial-config .ant-result {
    padding: 0;
  }
}
