:root {
  --main-bg-color: #f0f2f5;
}

html, body, #root {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.app-bg {
  height: 100%;
  width: 100%;
  /* background-image: url(/assets/images/ac-bg.jpg); */
  position: absolute;
  opacity: 0.3;
  top: 0px;
  background-repeat: repeat-y;
  background-size: cover;
}

.app-content {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.layout, .ant-layout {
  min-height: 100vh;
  /* min-width: 100vw; */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(240, 242, 245, 0.3) 0%,
    rgba(179, 203, 237, 0.3) 100%
  );
}

.section-container {
  margin: 20px 10%;
}

.half-width {
  width: 50%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.justify-c-center {
  justify-content: center;
}

.justify-c-start {
  justify-content: flex-start;
}

.justify-c-sb {
  justify-content: space-between;
}

.justify-c-sa {
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-top-large {
  margin-top: 44px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

.full-width {
  width: 100%;
}

@media (max-width: 700px) {
  .app-bg {
    background-size: cover;
    background-position-x: right;
  }
}

@media (max-width: 900px) {
  .app-bg {
    background-repeat: repeat;
    background-size: cover;
    background-position-x: right;
  }
}

/* OVERRIDES */

.ant-layout-footer {
  background: #edf1f5;
}

.ant-tabs-ink-bar {
  background: #f1a043;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f1a043;
}

.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 24px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  height: auto;
  background-color: #f8f9fb;
}

.header-public {
  justify-content: space-between;
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
}

#nav {
  height: 100%;
  font-size: 12px;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
  margin-right: 24px;
}

.user-avatar-container {
  cursor: pointer;
  align-items: center;
  flex: 1 1;
  justify-content: flex-end;
  width: -webkit-max-content;
  width: max-content;
}

#nav-user {
  font-size: 14px;
}

#nav-user .ant-menu-item {
  top: 5px;
}

.show-sm {
  display: none;
}

.more-menu-icon {
  font-size: 1.6rem;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .navbar-user-name {
    display: none;
  }
  .user-avatar-container {
    flex: 1 1;
  }

  .show-lg {
    display: none;
  }

  .show-sm {
    display: block;
  }
  #logo {
    padding-left: 5px;
  }
  #logo img {
    margin-right: 8px;
  }

  .header-actions {
    padding-right: 0;
  }

  #public-header .ant-btn {
    white-space: inherit;
    height: auto;
    margin-right: 20px;
  }
}

@media (max-width: 992px) {
  #react-week-calendar .show-sm {
    display: block !important;
  }
  #react-week-calendar .hidden-sm {
    display: none !important;
  }
  #react-week-calendar .week {
    display: block !important;
    width: 100%;
  }
  #react-week-calendar .day {
    max-width: 100% !important;
    width: 100%;
    margin-top: 10px !important;
    margin: 0px !important;
  }
  #react-week-calendar .event-list {
    margin: 10px 0px;
  }

  .calendar-responsive-header {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

#react-week-calendar .row.event-list {
  width: 100%;
}

#react-week-calendar .event-list-parent {
  padding: 0px !important;
}

#react-week-calendar .hidden-lg {
  display: none;
}

#react-week-calendar .hidden-sm {
  display: none;
}

#react-week-calendar .show-lg {
  display: flex;
}

#react-week-calendar .week {
  display: flex;
}

#react-week-calendar .day {
  flex-basis: 0;
  flex: 1 1;
  margin: 0px 2.5px;
}

#react-week-calendar .day:last-child {
  margin-right: 0px !important;
}

#react-week-calendar .day:first-child {
  margin-left: 0px !important;
  justify-content: flex-end;
}

#react-week-calendar .calendar-container {
  min-height: 200px;
}

#react-week-calendar .day-event {
  background: #e8e8e8;
  padding: 10px;
  margin: 2.5px 0px;
  width: 100%;
  word-break: break-all;
  cursor: pointer;
}

#react-week-calendar .day-event:last-child {
  margin-bottom: 0px !important;
}

#react-week-calendar .day-event:first-child {
  margin-top: 0px !important;
}

#react-week-calendar .week-header {
  /* background: #337ddf; */
  padding: 20px 0px;
  width: 100%;
}

#react-week-calendar .box-button {
  border-radius: 0 !important;
}

#react-week-calendar .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#react-week-calendar .day-0 {
  justify-content: center;
}

#react-week-calendar .day-1 {
  justify-content: center;
  margin-left: 10px;
}

#react-week-calendar .day-2 {
  margin-left: 9px;
}

#react-week-calendar .col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

#react-week-calendar .empty-state {
  color: #e8e8e8;
}

#react-week-calendar .btn {
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 0px;
  outline: none;
  border: none;
  transition-duration: 0.2s;
}

#react-week-calendar .btn:active {
  border-style: outset;
  border: none;
  background-color: #7ea8df;
}

#react-week-calendar .fade {
  -webkit-animation: fade;
          animation: fade;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.calendar-responsive-header {
  display: none;
}

.calendar-event-button {
  margin: 10px 3px;
  /* border-color: #5bb5f2; */
  /* color: #5bb5f2; */
}

.no-events {
  background: #e3e3e3;
  width: 100%;
  text-align: center;
  padding-top: 15%;
}


/* .calendar-event-button:hover {
  border-width: 2px;
  border-color: #8ddf4e;
  color: #8ddf4e;
} */

@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes btn-click {
  from {
    background-color: white;
  }
  to {
    background-clip: #337ddf;
  }
}

@keyframes btn-click {
  from {
    background-color: white;
  }
  to {
    background-clip: #337ddf;
  }
}

.hide-sm {
  display: inline-block;
}

@media (max-width: 600px) {
  .hide-sm {
    display: none;
  }
}

.result-card {
  margin: 20px 0;
}

.result-card .ant-card-body {
  display: flex;
  padding: 14px;
  flex-direction: column;
}

.card-div {
  width: 100%;
}

/* .doctor-card {
  padding: 30px;
} */

.doctor-card-identity {
  display: flex;
  align-items: center;
}

.doctor-card-identity-text {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem;
}

.doctor-card-info {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.doctor-list-container {
  max-width: 1100px;
  margin: auto;
  opacity: 0.85;
}

.ant-descriptions-item-content {
  word-break: inherit;
}

.CheckboxDoctor {
  margin-top:20px;
}
.result-card .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 6px 9px;
}
.ant-descriptions-item-content{
  display: flex;
    flex-direction: row-reverse;
}

.flexCenter {
    align-items : center;
    justify-content: center;
    display: flex;
}

th{
  width:40% !important;
}

@media (max-width: 600px) {
  .card-div {
    width: -webkit-min-content;
    width: min-content;
  }
  .result-card .ant-card-body {
    flex-wrap: wrap;
    padding: 0;
  }

  .doctor-list-container {
    margin: auto;
  }

  .result-card .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 6px 9px;
  }

  /* .doctor-card {
    padding: 30px 5px;
    margin: auto;
  } */


  .doctor-info-item {
    width: 40vw;
    word-break: break-word;
  }

  .show-lg {
    display: none;
  }

  .responsive-calendar {
    width: auto !important;
    margin: auto;
  }
}

/*  AUTH STYLES */
.auth-card {
  max-width: 600px;
  margin: auto;
}

/* Login STYLES */

.login-container {
  display: flex;
  justify-content: center;
}
.login-container .ant-card-body {
  padding-bottom: 0;
  flex: 1 1;
}

.login-form {
  width: 400px;
  max-width: 100%;
  margin: auto;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.mini-card-login {
  margin: 10px 5px;
  align-items: center;
}

.auth-container {
  margin: auto 1em;
  padding-bottom: 2em;
}

.signup-advertising-title {
  margin-bottom: 40px;
}

.signup-advertising-element {
  font-size: 1.2rem !important;
}

.signup-advertising-image {
  width: 30%;
  max-width: 320px;
  min-width: 240px;
  opacity: 0.7;
  margin: 14px auto;
}

.signup-divider {
  display: none;
}

.center-button {
  margin: auto;
  display: block;
}

@media (max-width: 700px) {
  .signup-container {
    flex-wrap: wrap;
  }

  .signup-advertising {
    max-width: none;
  }

  .signup-advertising-title {
    font-size: 1.3rem !important;
  }

  .signup-advertising-element {
    font-size: 1rem !important;
  }

  .signup-advertising-image {
    display: none;
  }

  .signup-divider {
    display: flex;
  }
  .auth-card {
    max-width: 100vw;
    width: -webkit-max-content;
    width: max-content;
    margin: 0;
  }

  .first-image-auth {
    width: 200px !important;
    position: unset !important;
    align-self: center;
  }
}

.second-image-auth {
  width: 180px;
  position: absolute;
  bottom: 10%;
  left: 9%;
  opacity: 0.8;
}

.first-image-auth {
  width: 170px;
  position: absolute;
  left: 30px;
  top: 30px;
  opacity: 0.8;
}
.disconnect{
  justify-content: center;
  align-items: center;
  display: flex;
  color: red;
}
.profile-gender-icon {
  font-size: 40px;
  margin-top: 10px;
}

.profile-gender-card {
  margin: 0px 15px;
}

#initial-config {
  opacity: 0.85;
}

/* .profile-selected-card {
  box-shadow: 0 1px 1px -2px rgb(24, 144, 255), 0 0px 1px 0 rgb(24, 144, 255),
    0 0px 12px 2px rgb(24, 144, 255);
} */

.profile-selected-gender {
  color: var(--antd-wave-shadow-color);
}

.profile-selected-gender .ant-card-meta-title {
  color: var(--antd-wave-shadow-color);
}

.card-container {
  margin: auto;
  width: 800px;
}

.custom-birthdate-inputs {
  display: flex;
  justify-content: stretch;
}


#initial-config .form-child-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.profile-gender-container {
  display: flex;
}

@media (max-width: 600px) {
  .card-container {
    width: auto !important;
  }

  #initial-config .ant-spin-nested-loading {
    width: -webkit-min-content;
    width: min-content;
  }

  #initial-config .ant-progress-line {
    width: 85%;
  }

  #birthdate-form .ant-form-item {
    width: -webkit-max-content;
    width: max-content;
  }

  #birthdate-form .form-submit-button {
    width: 100%;
  }

  .custom-birthdate-inputs {
    flex-wrap: wrap;
  }

  #initial-config .form-child-container {
    align-items: flex-start;
  }

  .profile-gender-container {
    flex-wrap: wrap;
    min-width: 85vw;
    justify-content: center;
  }

  .profile-gender-card {
    margin: 5px;
  }

  #initial-config .ant-result {
    padding: 0;
  }
}

.sigCanvas {
  border: 2px solid #e3ecf8;
  cursor: crosshair;
}

.initial-config-card {
  margin: 10% 8%;
}

.initial-config-steps-container {
  padding: 30px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.sig-pad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responsive-sigpad-msg {
  display: none;
}

.signature-radio-group {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

@media (max-width: 600px) {
  .initial-config-card {
    margin: 10px 3px;
  }
  .initial-config-steps-container {
    padding: 0;
    margin-top: 0;
  }

  #initial-config-stripe .ant-btn-lg {
    height: auto;
  }
  #initial-config-stripe .ant-btn {
    white-space: inherit;
  }
  .sig-pad-container {
    display: none;
  }
  .responsive-sigpad-msg {
    display: block;
  }
  .signature-radio-group {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .signature-radio-group .ant-upload-list {
    max-width: 60vw;
  }
}

#book-telec-form .stripe-input {
  padding: 7.5px 11px;
}

#checkout-card  .ant-card-body{

}

#unpadded-card {
  padding: 0;
}

.booking-form {
  margin: auto;
  width: 800px;
  height: 100%;
}

.booking-steps-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.booking-checkout-form {
  margin-top: 15px;
}

.accepted-cards-list {
  display: flex;
  justify-content: space-between;
  margin: 15px;
}

.ant-checkbox-form .ant-form-item{
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .booking-form {
    margin: 0;
    width: -webkit-min-content;
    width: min-content;
    height: 100%;
  }

  .booking-steps-container{
    width: 80vw;
  }

  .booking-form .ant-card-actions > li > span {
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  .booking-form .ant-card-actions > li > span > button {
    margin-bottom: 10px;
  }
  
  .booking-form .cPurposes-select-form {
    width: 80vw;
  }

  .booking-checkout-form {
   width: 80vw;
  }

  .accepted-cards-list {
    flex-wrap: wrap;
  }

  .booking-form  .ant-card-body { 
    width: -webkit-min-content; 
    width: min-content;
  }
}
.profile-section .ant-tabs-tab {
  width: 50%;
  display: flex;
  justify-content: center;
}

.profile-section .ant-tabs-nav-list {
    width: 100%
}

.stetoscope-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
}

.ant-tabs-tab-active .stetoscope-icon {
    color: #f1a043;
}

.profile-section .ant-tabs-nav-operations {
    display: none !important;
}

.profile-tab {
    font-size: 20px;
}

.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: -webkit-min-content;
  height: min-content;
  max-height: calc(100vh - 180px);
  margin-top: 10px;
  overflow: auto;
  margin-right: 20px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.images-container {
  justify-content: space-between;
  align-content: center;
  margin-top: -60px;
  align-items: baseline
}

.authorized-doctors-list .ant-list-item {
  padding: 16px 0;
}

.authorized-doctors-list .ant-list-item-action  button {
  color: #ff4d4f;
}

@media (max-width: 600px) {
  .images-container {
    flex-wrap: wrap;
  }
}

.rotate-0 .image-gallery-image {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.image-gallery-slide {
  background-color: inherit;
  position: absolute;
  height: auto;
  top: inherit;
  left: inherit;
}

.image-gallery {
  max-width: 50vw;
}

.authorized-doctors-card {
  height: -webkit-min-content;
  height: min-content;
  max-height: calc(100vh - 180px);
  margin-top: 10px;
  overflow: auto;
  margin-right: 0px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 120px);
}

.images-container {
  justify-content: space-between;
  align-content: center;
  margin-top: -60px;
  align-items: baseline
}

.doc-file-radio {
  height: 12em;
  width: 15em;
  margin: .5em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.authorized-doctors-list .ant-list-item {
  padding: 16px 0;
}

.authorized-doctors-list .ant-list-item-action  button {
  color: #ff4d4f;
}

.ant-card-body {
  padding:10px;
}
.ant-select {
  width: 100% !important;
}
.ant-collapse-content ant-collapse-content-active{
  width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
}

@media (max-width: 600px) {
  .images-container {
    flex-wrap: wrap;
  }
}

#local-video-preview {
    width: 450px;
    height: 450px;
}
.vid-preview-item {
    margin: 0 10px;
}

#local-video {
  max-width: 80%;
  max-height: 100%;
  display: block;
  margin: 10px auto 20px;
}

#remote-video {
    height: 100%;
    max-width: 80%;
  display: block;
  margin: 10px auto 20px;
}


.message--sent {
  position: absolute;
  right: 3.15rem;
  bottom: -1.075rem;
  display: flex;
  align-items: center;
}

.message--sent svg {
  margin-left: 0.25rem;
}

.message--sent-date {
  position: absolute;
  left: 3.75rem;
  bottom: -1.075rem;
}

.message-text-contact {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  font-size: 1rem;
  margin: -0.625rem 0 0.25rem 0.75rem;
  background-color: #f0f2f5;
  word-wrap: anywhere;
 
}

.message:not(:first-child) {
  margin-top: 2rem;
}
.message:first-child {
  margin-top: 1rem;
}


.message-text-user {
  display: flex;
  align-items: flex-start;
  border-radius: 0.625rem;
  padding: 0.625rem;
  margin: -0.625rem 0.75rem 0.25rem 0;
  font-size: 1rem;
  background-color: #1890ff;
  color: #fff;
  word-wrap: anywhere;
}

.message-user-receiver {
  flex-direction: row-reverse;
}

.message { 
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;
}


.message > .ant-avatar.ant-avatar-icon {
  flex-shrink: 0;
}

.telec-messages-card {
  height: 100%;
}

.telec-messages-card .ant-card-body { 
  height: 80%;
}

.googleplay-button {
  display: flex;
  align-items: center;
  height: auto;
  align-self: start;
  margin-bottom: 10px;
}

.telec-messages-card .message-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.telec-messages-card .message-input-wrapper .input-formitem{
  flex-grow: 1;
}

.telec-messages-card .message-input-wrapper .input-actions{
  margin-left: 1em;
}
.telec-modal .ant-modal-body {
    padding: 0;
}

.telec-modal #dashboard-telec-element {
    margin: 24px 0 0 !important;
    opacity: 1 !important;
}

.device-icon {
    font-size: 35px;
    margin-top: 10px;
    color: #50abff;
}
.sidebar-menu-item {
  align-items: center;
  font-size: 12px;
}

.sidebar {
  background-color: white !important;
  border-right: 0px !important;
}

.sidebar.collapsible {
  position: absolute;
  z-index: 1;
  height: 100%;
}

.sidebar .ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 52px;
  line-height: 40px;
}

.sidebar .ant-divider-horizontal {
  margin: 12px 0;
}

.sidebar .ant-menu-item-selected {
  font-weight: 600;
  color: #f09f42 !important;
}

.sidebar .ant-menu-item {
  color: #000000 !important;
}

.sidebar .ant-menu-item:hover {
  color: #1890ff !important;
}

.sidebar .ant-menu-item-disabled {
  color: #000000 !important;
}

.sidebar .ant-layout-sider-trigger {
  background-color: #f04842;
}

.sidebar .ant-menu {
  color: #fff;
  margin-top: 34px;
}

.sidebar .ant-menu-sub.ant-menu-inline {
  background: inherit;
}
.sidebar .ant-menu-submenu-selected {
  color: #ffff !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-active {
  color: #ffff !important;
  font-weight: 600;
}

.sidebar .ant-menu-item-active {
  color: currentColor !important;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-arrow {
  color: #ffff;
  font-weight: 600;
}

.sidebar .ant-menu-submenu-title {
  padding-left: 17px !important;
}

.sidebar.ant-layout-sider-collapsed {
  display: none;
}

.sidebar-collapse-trigger {
  position: fixed;
  z-index: 2;
  font-size: 1.75em;
  top: 15px;
  left: 15px;
}

