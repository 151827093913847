@import "../../app/styles.css";

.header-top {
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  padding: 24px;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  height: auto;
  background-color: #f8f9fb;
}

.header-public {
  justify-content: space-between;
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
}

#nav {
  height: 100%;
  font-size: 12px;
}

#logo {
  height: 42px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img {
  position: relative;
  height: 42px;
  margin-right: 24px;
}

.user-avatar-container {
  cursor: pointer;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  width: max-content;
}

#nav-user {
  font-size: 14px;
}

#nav-user .ant-menu-item {
  top: 5px;
}

.show-sm {
  display: none;
}

.more-menu-icon {
  font-size: 1.6rem;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .navbar-user-name {
    display: none;
  }
  .user-avatar-container {
    flex: 1;
  }

  .show-lg {
    display: none;
  }

  .show-sm {
    display: block;
  }
  #logo {
    padding-left: 5px;
  }
  #logo img {
    margin-right: 8px;
  }

  .header-actions {
    padding-right: 0;
  }

  #public-header .ant-btn {
    white-space: inherit;
    height: auto;
    margin-right: 20px;
  }
}
