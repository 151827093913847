@import "~antd/dist/antd.css";

:root {
  --main-bg-color: #f0f2f5;
}

html, body, #root {
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: auto;
}

.app-bg {
  height: 100%;
  width: 100%;
  /* background-image: url(/assets/images/ac-bg.jpg); */
  position: absolute;
  opacity: 0.3;
  top: 0px;
  background-repeat: repeat-y;
  background-size: cover;
}

.app-content {
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.layout, .ant-layout {
  min-height: 100vh;
  /* min-width: 100vw; */
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to top,
    rgba(240, 242, 245, 0.3) 0%,
    rgba(179, 203, 237, 0.3) 100%
  );
}

.section-container {
  margin: 20px 10%;
}

.half-width {
  width: 50%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.justify-c-center {
  justify-content: center;
}

.justify-c-start {
  justify-content: flex-start;
}

.justify-c-sb {
  justify-content: space-between;
}

.justify-c-sa {
  justify-content: space-around;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.m-auto {
  margin: auto;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-top-large {
  margin-top: 44px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

.full-width {
  width: 100%;
}

@media (max-width: 700px) {
  .app-bg {
    background-size: cover;
    background-position-x: right;
  }
}

@media (max-width: 900px) {
  .app-bg {
    background-repeat: repeat;
    background-size: cover;
    background-position-x: right;
  }
}

/* OVERRIDES */

.ant-layout-footer {
  background: #edf1f5;
}

.ant-tabs-ink-bar {
  background: #f1a043;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f1a043;
}
